<template>
<div class="layout-paginator-container">
  <span @click.stop="!leftArrowDisabled && leftArrowClick()">
    <font-awesome-icon v-if="leftArrowDisabled" :icon="['fas', 'chevron-circle-left']" class="disabled" />
    <font-awesome-icon v-else :icon="['fas', 'chevron-circle-left']" class="enabled" />

  </span>
  
  <span class="progress-text">
    {{ currentPage }}/{{ totalPages }}
  </span>
  
  <span @click.stop="!rightArrowDisabled && rightArrowClick()">
    <font-awesome-icon v-if="rightArrowDisabled" :icon="['fas', 'chevron-circle-right']" class="disabled" />
    <font-awesome-icon v-else :icon="['fas', 'chevron-circle-right']" class="enabled" />
  </span>
</div>
</template>
    
<script>

export default {
  props: {
    totalPages: {
      type: Number,
      default: 10
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },


  computed: {
    leftArrowDisabled() {
      return this.currentPage === 1;
    },
    rightArrowDisabled() {
      return this.currentPage === this.totalPages;
    }
  },
  
  methods: {
    leftArrowDisabledClass() {
      return this.leftArrowDisabled() ? "disabled" : ""
    },
    leftArrowClick() {
      // this.currentPage--;
      this.$emit("update:currentPage", this.currentPage-1);
      this.$emit("arrowClick");
    },
    
    rightArrowClick() {
      // this.currentPage++;
      this.$emit("update:currentPage", this.currentPage+1);
      this.$emit("arrowClick");
    },

  }
};
</script>
  

<style scoped lang="scss">
@import "~@/assets/scss/abstracts/_variables.scss";
.layout-paginator-container {
  background-color: white;
  color: $purple;
  padding: 2px 4px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
  gap: 8px;
  font-size: 0.9em;

  .progress-text {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  span {
    display: flex;
    .enabled:hover {
      color: $purple-dark;
      transition: color 0.3s ease; /* Smooth transition over 0.3 seconds */
    }
  }

  .disabled {
    color: $purple-x;
  }
}

</style>